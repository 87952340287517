<template>
  <section class="container">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title column">
          <span class="is-size-4 has-text-weight-normal">Indicação de Condutor</span>
          <b-tooltip label="Cancelar Indicação de Condutor" class="is-pulled-right">
            <b-button type="is-primary" inverted rounded @click="cancel">
              <b-icon icon="close-circle" size="is-medium" />
            </b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="card-content">
        <div class="steps">
          <div
            v-for="(step, index) in steps"
            :key="index"
            :class="{
              'step-item': true,
              'is-active': step.isActive,
              'is-completed': step.isCompleted
            }"
          >
            <div class="step-marker">
              {{ step.label }}
            </div>
            <div class="step-details">
              {{ step.detail }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="step-content is-active">
          <step-cadastro-condutor
            v-show="currStep === 1"
            ref="step1"
            :condutor="condutor"
          />
          <step-upload-imagem
            v-show="currStep === 2"
            ref="step2"
            :imagem="imagens.formIndc"
            title="Formulário Assinado"
            description="Formulário da indicação de condutor preenchido e assinado pelo proprietário do veículo e pelo condutor infrator."
            @atualiza-imagem="imagens.formIndc = $event"
          />
          <step-upload-imagem
            v-show="currStep === 3"
            ref="step3"
            :optional="true"
            :imagem="imagens.docResp"
            title="Documento de Responsabilidade"
            description="Termo de responsabilidade por infrações cometidas pelo condutor que comprove a posse do veículo no momento do cometimento da infração, sendo o veículo de propriedade de pessoa jurídica."
            @atualiza-imagem="imagens.docResp = $event"
            @sem-imagem="semImagem"
          />
          <step-confirmar
            v-if="currStep === 4"
            ref="step4"
            :infracao="infracao"
            :condutor="condutor"
            :imagens="imagens"
          />
          <step-enviar-indicacao
            v-if="currStep === 5"
            ref="step5"
            :infracao="infracao"
            :condutor="condutor"
            :imagens="imagens"
          />
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-centered">
          <div class="column is-narrow">
            <b-button
              v-show="currStep > 1 && currStep < 5"
              type="is-verde"
              class="level-item button-width"
              @click="previousStep"
            >
              Voltar
            </b-button>
          </div>
          <div class="column is-narrow">
            <b-button
              v-show="currStep < 5"
              type="is-primary"
              class="level-item button-width"
              @click="nextStep"
            >
              Proximo
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StepCadastroCondutor from '../../../../components/infracao/indicacao/step-cadastro-condutor.vue'
import StepUploadImagem from '../../../../components/infracao/indicacao/step-upload-imagem.vue'
import StepConfirmar from '../../../../components/infracao/indicacao/step-confirmar.vue'
import StepEnviarIndicacao from '../../../../components/infracao/indicacao/step-enviar-indicacao.vue'

export default {
  name: 'IndicacaoCondutor',
  components: {
    StepCadastroCondutor,
    StepUploadImagem,
    StepConfirmar,
    StepEnviarIndicacao
  },
  layout: process.env.VUE_APP_LAYOUT_DEFAULT,
  validate ({ params }) {
    return /^\d+$/.test(params.infracao_id)
  },
  data () {
    return {
      infracao: {},
      currStep: 1,
      condutor: {
        nomeCompleto: '',
        cnh: '',
        ufCNH: null,
        tipoCNH: 'cnh',
        semAssinatura: false,
        logradouro: '',
        numero: null,
        complemento: '',
        cep: '',
        bairro: '',
        municipio: '',
        uf: '',
        celular: '',
        email: ''
      },
      imagens: {
        formIndc: null,
        docResp: null
      },
      steps: [
        {
          id: 1,
          label: '1',
          isActive: true,
          isCompleted: false,
          detail: 'Cadastro de Condutor',
          validate: el => el.$refs.step1.validate()
        },
        {
          id: 2,
          label: '2',
          isActive: false,
          isCompleted: false,
          detail: 'Formulário Assinado',
          validate: el => el.$refs.step2.validate()
        },
        {
          id: 3,
          label: '3',
          isActive: false,
          isCompleted: false,
          detail: 'Documento de Responsabilidade',
          validate: el => el.$refs.step3.validate()
        },
        {
          id: 4,
          label: '4',
          isActive: false,
          isCompleted: false,
          detail: 'Conferir',
          validate: el => el.$refs.step4.validate()
        },
        {
          id: 5,
          label: '5',
          isActive: false,
          isCompleted: false,
          detail: 'Enviar',
          validate: el => el.$refs.step5.validate()
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      podeIndicarCondutor: 'infracoes/podeIndicarCondutor',
      getInfracao: 'infracoes/infracao'
    })
  },
  async beforeMount () {
    this.getDados()

    const infracaoId = Number(this.$route.params.infracao_id)
    const infracao = await this.getInfracao(infracaoId)
    if (infracao == null) {
      this.returnIndexPage()
    }
    this.infracao = infracao

    const podeIndicar = await this.podeIndicarCondutor(infracaoId)
    if (!podeIndicar) {
      this.$buefy.dialog.alert({
        message: 'Desculpe, não é possivel indicar esta infração.',
        confirmText: 'Retornar',
        onConfirm: () => {
          this.$router.push('/infracoes')
        }
      })
    }
  },
  methods: {
    ...mapActions('infracoes', [
      'getInfracoes'
    ]),
    getDados () {
      let hash = this.$store.getters['sessions/hash']

      if (hash === '' || hash === null) {
        hash = localStorage.getItem('placa-renavam-hash')
        this.$store.commit('session/SESSION_SUCCESS', hash)
      }
      return this.getInfracoes(hash)
    },
    changeCurrStep (next) {
      this.currStep = this.steps.length + 1 > next ? next : 1
      this.steps.forEach((step) => {
        step.isActive = step.id === this.currStep
        step.isCompleted = step.id < next
      })
    },
    nextStep () {
      this.steps[this.currStep - 1].validate(this).then((ret) => {
        if (ret) {
          this.changeCurrStep(this.currStep + 1)
        }
      })
    },
    semImagem (value) {
      this.condutor.semAssinatura = value
    },
    previousStep () {
      this.changeCurrStep(this.currStep - 1)
    },
    returnIndexPage () {
      this.$buefy.dialog.alert({
        message:
          'Sua sessão expirou. Por gentileza, consulte a placa novamente',
        confirmText: 'Retornar',
        onConfirm: () => {
          this.$store.commit('infracoes/limpaInfracoes')
          localStorage.clear()
          this.$router.push('/')
        }
      })
    },
    cancel () {
      this.$buefy.dialog.confirm({
        message: 'Tem certeza de que deseja cancelar a indicação de condutor?',
        confirmText: 'Sair',
        onConfirm: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style>
.button-width {
  width: 100px;
}
</style>
