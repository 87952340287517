<template>
  <div class="columns is-centered">
    <div class="column" style="max-width:720px">
      <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false" />
      <div v-if="loading" class="is-size-2">
        Enviando....
      </div>
      <div v-show="!loading && !error">
        <div class="is-size-4">
          Concluido
        </div>
        <div class="is-size-5" style="margin-top:20px">
          Seu processo foi protocolado com sucesso e os documentos serão analisados. Acompanhe no site se os mesmos foram aceitos.
        </div>
        <div class="has-text-weight-semibold is-size-4" style="margin-top:20px">
          {{ protocolo }}
        </div>
        <div>
          <div v-if="false" style="margin-top:50px">
            <div v-if="!emailEnviado">
              <span class="has-text-weight-semibold">Deseja acompanhar o processo de indicação por email?</span>
              <b-field
                :type="{ 'is-danger': errors.has('email') }"
                :message="errors.first('email')"
              >
                <b-input
                  v-model="email"
                  v-validate="'required|email'"
                  :disabled="enviandoEmail"
                  type="email"
                  name="email"
                />
                <p class="control">
                  <b-button :loading="enviandoEmail" type="is-primary" label="Enviar" @click="enviarEmail" />
                </p>
              </b-field>
            </div>
            <div v-else>
              <span class="has-text-weight-semibold">Seu email foi salvo, você será avisado quando sua indicação for analisada.</span>
            </div>
          </div>
        </div>
      </div>
      <div v-show="error">
        <p class="is-size-4">
          {{ mensagemErro != '' ? mensagemErro : 'Ocorreu um erro ao enviar sua indicação de condutor. Por favor tente novamente mais tarde.' }}
        </p>
      </div>
      <router-link to="/infracoes">
        <b-button
          v-if="!loading"
          label="Sair"
          type="is-primary"
          style="margin-top:100px"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import defaultAxios from '../../../plugin/axios'
import axios from 'axios'
export default {
  name: 'StepEnviarIndicacao',
  props: {
    infracao: {
      type: Object,
      required: true
    },
    condutor: {
      type: Object,
      required: true
    },
    imagens: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      protocolo: '',
      error: false,
      email: '',
      enviandoEmail: false,
      emailEnviado: false,
      mensagemErro: ''
    }
  },
  beforeMount () {
    this.enviarIndicacao()
  },
  methods: {
    async enviarIndicacao () {
      const hash = this.$store.getters['sessions/hash']
      const data = await montaIndicacao(this.infracao, this.condutor, this.imagens, hash, axios)
      await defaultAxios.post(
        `${process.env.VUE_APP_BASE_URL}/indicacao/${process.env.VUE_APP_CLIENTID}/${this.infracao.idInfracao}/${hash}`,
        data).then((ret) => {
        this.loading = false
        this.protocolo = ret.data.protocolo
      }).catch(erro => {
        this.mensagemErro = erro.response.data[0].value
        this.loading = false
        this.error = true
      })
    },
    enviarEmail () {
      this.enviandoEmail = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          const hash = this.$store.getters['sessions/hash']
          const data = {
            email: this.email,
            protocolo: this.protocolo,
            dataCadastro: new Date()
          }
          axios.post(
            `${process.env.VUE_APP_INDICACAO}/indicacaocidadao/cadastraremail/${hash}`,
            data).then(() => {
            this.enviandoEmail = false
            this.emailEnviado = true
          })
        } else {
          this.enviandoEmail = false
        }
      })
    }
  }
}

async function montaIndicacao (infracao, condutor, imagens, hash, axios) {
  const [formularioIndicacaoId] = await Promise.all([
    enviaImagemIndicacao(imagens.formIndc, 'formularioIndicacao', hash, axios)
  ])

  const docResponsabilidadeId = !condutor.semAssinatura
    ? await enviaImagemIndicacao(imagens.docResp, 'documentoResponsabilidade', hash, axios)
    : 0

  return {
    nomeInfrator: condutor.nomeCompleto,
    CNHCondutor: condutor.cnh,
    UFCNH: condutor.ufCNH,
    tipoCNH: condutor.tipoCNH,
    semAssinatura: condutor.semAssinatura,
    formularioIndicacaoId,
    documentoResponsabilidadeId: docResponsabilidadeId,
    enderecoCondutor: {
      logradouro: condutor.logradouro,
      numero: condutor.numero,
      complemento: condutor.complemento,
      cep: condutor.cep,
      bairro: condutor.bairro,
      municipio: condutor.municipio,
      uf: condutor.uf
    },
    celularCondutor: condutor.celular ? condutor.celular.replace(/\D/g, '') : null,
    emailCondutor: condutor.email ? condutor.email : null
  }
}

function enviaImagemIndicacao (file, tipoDoc, hash, axios) {
  const formData = new FormData()
  formData.append(tipoDoc, file, file.filename)

  return new Promise((resolve, reject) => {
    axios.post(
      `${process.env.VUE_APP_INDICACAO}/docindicacao/${process.env.VUE_APP_CLIENTID}/${hash}`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((ret) => {
      if (Array.isArray(ret.data)) {
        resolve(ret.data[0].id)
      } else {
        reject(Error('RetornoInvalido'))
      }
    }).catch((err) => {
      reject(err)
    })
  })
}
</script>
