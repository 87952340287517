<template>
  <div class="">
    <div class="column is-6 is-offset-3">
      <form>
        <div class="is-size-4">
          Dados do Condutor (Real Infrator)
        </div>
        <div style="padding-bottom: 20px" />
        <b-field
          label="Nome Completo"
          class="mb-0"
          @change="$emit('change', condutor)"
        >
          <b-input
            v-model="condutorData.nomeCompleto"
            name="Nome"
            @change="$v.condutorData.nomeCompleto.$touch()"
          />
        </b-field>
        <div
          class="error mt-1 mb-1"
          v-if="
            !$v.condutorData.nomeCompleto.required &&
            $v.condutorData.nomeCompleto.$dirty
          "
        >
          Campo requerido
        </div>
        <div style="padding-bottom:8px" class="mt-3 mb-2">
          <span class="has-text-weight-bold">Tipo do Documento de Habilitação</span>
        </div>
        <b-field>
          <b-radio
            v-model="condutorData.tipoCNH"
            native-value="cnh"
          >
            CNH
          </b-radio>
          <b-radio
            v-model="condutorData.tipoCNH"
            native-value="pgu"
          >
            PGU
          </b-radio>
          <b-radio
            v-model="condutorData.tipoCNH"
            native-value="extrangeira"
          >
            Habilitação Estrangeira
          </b-radio>
        </b-field>
        <div class="columns mt-2">
          <div class="column">
            <b-field
              :label="labelNumeroDocH"
              style="max-width: 300px"
              class="mb-0"
            >
              <b-input
                v-model="condutorData.cnh"
                name="Numero da Habilitação"
                @change="$v.condutorData.cnh.$touch()"
                placeholder="Digite sua CNH"
                maxlength="11"
              />
            </b-field>
            <div
              class="error mb-1 mt-1"
              v-if="
                !$v.condutorData.cnh.required &&
                $v.condutorData.cnh.$dirty
              "
            >
              Campo requerido
            </div>
            <div
              class="error mb-1"
              v-if="!$v.condutorData.cnh.numeric &&
                condutorData.tipoCNH === 'cnh'
              "
            >
              Este campo só aceita números.
            </div>
            <div
              class="error mb-1"
              v-if="!$v.condutorData.cnh.minLength &&
                condutorData.tipoCNH === 'cnh'
              "
            >
              O campo deve ter no mínimo
              {{ $v.condutorData.cnh.$params.minLength.min }} dígitos.
            </div>
          </div>
          <div class="column">
            <b-field
              label="UF"
              class="mb-0"
            >
              <b-select
                v-model="condutorData.ufCNH"
                :disabled="condutorData.tipoCNH === 'extrangeira'"
                @change="$v.condutorData.ufCNH.$touch()"
                placeholder="UF Habilitação"
                name="UF"
              >
                <option
                  v-for="estado in estados"
                  :key="estado"
                  :value="estado"
                >
                  {{ estado }}
                </option>
              </b-select>
            </b-field>
            <div
            class="error mb-1 mt-1"
            v-if="
              !$v.condutorData.ufCNH.required &&
              $v.condutorData.ufCNH.$dirty &&
              condutorData.tipoCNH !=='extrangeira'
            "
            >
              Campo requerido
            </div>
          </div>
        </div>
        <b-collapse
          :open="false"
          aria-id="panelEndereco"
          class="block"
          animation="slide"
        >
          <template #trigger>
            <b-checkbox
              @input="mostrarEndereco"
              aria-controls="panelEndereco"
            >
              Informar endereço do condutor
            </b-checkbox>
          </template>
          <div class="block">
            <div class="is-size-4">
              Informações Adicionais
            </div>
            <div class="columns mt-2">
              <div class="column is-three-quarters">
                <b-field
                  label="Endereço"
                  class="mb-0"
                >
                  <b-input
                    v-model="condutorData.logradouro"
                    name="Logradouro"
                    @change="$v.condutorData.logradouro.$touch()"
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label="Número"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.numero"
                    name="Numero"
                    @change="$v.condutorData.numero.$touch()"
                  />
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <b-field
                  label="Complemento"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.complemento"
                    name="Complemento"
                    @change="$v.condutorData.complemento.$touch()"
                  />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field
                  label="CEP"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.cep"
                    v-cleave="cepMask"
                    name="CEP"
                    @change="$v.condutorData.cep.$touch()"
                  />
                </b-field>
                <div
                  class="error mb-1"
                  v-if="!$v.condutorData.cep.minLength"
                >
                  O campo deve ter no mínimo
                  {{ $v.condutorData.cep.$params.minLength.min }} dígitos.
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third">
                <b-field
                  label="Bairro"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.bairro"
                    name="Bairro"
                    @change="$v.condutorData.bairro.$touch()"
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label="Município"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.municipio"
                    name="Município"
                    @change="$v.condutorData.municipio.$touch()"
                  />
                </b-field>
              </div>
              <div class="column is-one-quarter">
                <b-field
                  label="UF"
                  class="mb-0"
                >
                  <b-select
                    v-model="condutorData.uf"
                    @change="$v.condutorData.uf.$touch()"
                    placeholder="UF"
                    name="UFEndereco"
                  >
                    <option
                      v-for="estado in estados"
                      :key="estado"
                      :value="estado"
                    >
                      {{ estado }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third">
                <b-field
                  label="Celular"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.celular"
                    v-mask="'(##) #####-####'"
                    name="Celular"
                    @change="$v.condutorData.celular.$touch()"
                  />
                </b-field>
                <div class="error mb-1" v-if="!$v.condutorData.celular.minLength">
                  Número inválido
                </div>
              </div>
              <div class="column is-two-quarters">
                <b-field
                  label="E-mail"
                  class="mb-0"
                  @change="$emit('change', condutor)"
                >
                  <b-input
                    v-model="condutorData.email"
                    name="Email"
                    @change="$v.condutorData.email.$touch()"
                  />
                </b-field>
                <div class="error mb-1" v-if="!$v.condutorData.email.email && $v.condutorData.email.$dirty">
                  E-mail inválido
                </div>
              </div>
            </div>
        </div>
        </b-collapse>
      </form>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { required, requiredIf, numeric, minLength, maxLength, email } from 'vuelidate/lib/validators'
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  name: 'StepCadastroCondutor',
  props: {
    condutor: {
      type: Object,
      required: true
    }
  },
  directives: { cleave },
  data () {
    return {
      condutorData: {},
      cepMask: {
        delimiters: ['-'],
        blocks: [5, 3],
        numericOnly: true
      },
      showEndereco: false,
      estados: ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
    }
  },
  validations: {
    condutorData: {
      nomeCompleto: { required },
      tipoCNH: { required },
      cnh: { required, numeric, minLength: minLength(11), maxlength: maxLength(11) },
      celular: { minLength: minLength(15), maxlength: maxLength(15) },
      ufCNH: {
        required:
        requiredIf(function () {
          if (['cnh', 'pgu'].includes(this.condutorData.tipoCNH)) {
            return true
          } else {
            this.condutorData.ufCNH = null
            return false
          }
        })
      },
      cep: { minLength: minLength(9) },
      email: {
        email
      }
    }
  },
  computed: {
    labelNumeroDocH () {
      switch (this.condutor.tipoCNH) {
        case 'cnh':
          return 'Número da CNH'
        case 'pgu':
          return 'Número da PGU'
        case 'extrangeira':
          return 'Número da Habilitação'
        default:
          return 'Número do Documento de Habilitação'
      }
    }
  },
  mounted () {
    this.condutorData = this.condutor
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        if (!this.$v.$invalid) {
          resolve(true)
        } else {
          this.$v.$touch()
          resolve(false)
        }
      })
    },
    mostrarEndereco (e) {
      this.showEndereco = !this.showEndereco

      if (this.showEndereco === false) {
        this.limparEndereco()
      }
    },
    limparEndereco () {
      this.condutorData.logradouro = ''
      this.condutorData.numero = null
      this.condutorData.complemento = ''
      this.condutorData.cep = ''
      this.condutorData.bairro = ''
      this.condutorData.municipio = ''
      this.condutorData.uf = ''
      this.condutorData.celular = ''
      this.condutorData.email = ''
    }
  }
}
</script>
<style>
.error {
  color: #d11313;
  font-size: 12px;
}
</style>
