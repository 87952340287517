<template>
  <div class="columns is-centered">
    <div class="column is-12">
      <div class="is-size-4 div-pad-1">
        Conferir Indicação
      </div>
      <div class="is-size-5 div-pad-2">
        Infração
      </div>
      <div>
        <span class="has-text-weight-semibold">Placa:</span> <span>{{ infracao.placa }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">AIT:</span> <span>{{ infracao.ait }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">Enquadramento:</span> <span>{{ infracao.enquadramentoCompleto }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">Local:</span> <span>{{ infracao.local }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">Data/Hora da Infração:</span> <span>{{ dataHoraInfracao }}</span>
      </div>
      <div class="is-size-5 div-pad-2">
        Condutor
      </div>
      <div>
        <span class="has-text-weight-semibold">Nome:</span> <span>{{ condutor.nomeCompleto }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">Tipo de Habilitação:</span> <span>{{ tipoHabilitacao }}</span>
      </div>
      <div>
        <span class="has-text-weight-semibold">Documento:</span> <span>{{ condutor.cnh }}</span>
        <span class="has-text-weight-semibold"> UF:</span> <span>{{ condutor.ufCNH }}</span>
      </div>
      <div>
        <span v-show="condutor.semAssinatura" class="has-text-weight-semibold">Não possuo termo de responsabilidade por infrações cometidas.</span>
      </div>
      <div class="is-size-5 div-pad-2">
        Documentos
      </div>
      <div class="columns">
        <div class="column">
          <div class="is-size-7">
            Formulário de Indicação
          </div>
          <img v-if="files.formIndc.type !== 'application/pdf'" v-show="files.formIndc.b64 !== null" :src="files.formIndc.b64" class="mini-image">
          <object v-else type="application/pdf" :data="files.formIndc.b64" class="mini-pdf" />
        </div>
        <div v-show="!condutor.semAssinatura && files.docResp.b64 !== null" class="column">
          <div class="is-size-7">
            Documento de responsabilidade
          </div>
          <img v-if="files.docResp.type !== 'application/pdf'" :src="files.docResp.b64" class="mini-image">
          <object v-else type="application/pdf" :data="files.docResp.b64" class="mini-pdf" />
        </div>
      </div>
      <div class="field" style="padding-top:50px;">
        <b-field>
          <b-checkbox v-model="accept" name="flag-terms">
            <div class="has-text-weight-semibold">
              Declaro, sob pena da lei, que assumo total responsabilidade pela veracidade das informações aqui prestadas.
            </div>
          </b-checkbox>
        </b-field>
        <span
          for="accept"
          class="error"
          v-if="$v.accept.$invalid"
        >
          Você se responsabiliza pelas informações inseridas?
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { sameAs } from 'vuelidate/lib/validators'
import { format } from 'date-fns'
export default {
  name: 'StepConfirmar',
  props: {
    infracao: {
      type: Object,
      required: true
    },
    condutor: {
      type: Object,
      required: true
    },
    imagens: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      accept: false,
      files: {
        formIndc: {
          b64: null,
          type: null
        },
        docResp: {
          b64: null,
          type: null
        }
      }
    }
  },
  validations: {
    accept: {
      sameAs: sameAs(() => true)
    }
  },
  computed: {
    tipoHabilitacao () {
      switch (this.condutor.tipoCNH) {
        case 'cnh':
          return 'Carteira Nacional de Habilitação (CNH)'
        case 'pgu':
          return 'Prontuário Geral Único (PGU)'
        case 'extrangeira':
          return 'Habilitação Extrangeira'
        default :
          return ''
      }
    },
    dataHoraInfracao () {
      return format(new Date(this.infracao.dataHoraInfracao), 'dd/MM/yyyy HH:mm')
    }
  },
  async beforeMount () {
    if (this.imagens) {
      this.files.formIndc.b64 = await getFileB64(this.imagens.formIndc)
      this.files.formIndc.type = await getFileType(this.imagens.formIndc)

      this.files.docResp.b64 = await getFileB64(this.imagens.docResp)
      this.files.docResp.type = await getFileType(this.imagens.docResp)
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        if (!this.$v.$invalid) {
          resolve(true)
        }
      })
    }
  }
}

function getFileB64 (file) {
  if (file != null) {
    return window.URL.createObjectURL(file)
  }

  return null
}

function getFileType (file) {
  if (file != null) {
    return file.type
  }

  return null
}
</script>

<style>
  .div-pad-1 {
    padding-bottom: 5px;
  }

  .div-pad-2 {
    padding-top: 20px;
    padding-bottom: 5px;
  }

  .mini-image {
    max-width: 256px;
    max-height: 512px;
  }

  .mini-pdf {
    width: 300px;
    height: 400px;
  }
</style>
